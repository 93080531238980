import React from 'react';
import styles from './FeedbackPage.module.scss';

import Page from 'components/Page';
import ScrollWrapper from 'components/ScrollWrapper';
import Container from 'components/Container';
import BackButton from 'components/BackButton';
import StandardContent from 'components/StandardContent';
import Button from 'components/Button';
import { useTranslation } from 'features/translator';
import { ReactComponent as Kebab } from './assets/kebab.svg';

const FeedbackPage: React.FunctionComponent = () => {
  const translation = useTranslation();
  const [restaurantTextBeforeIcon, restaurantTextAfterIcon] = translation
    .get('feedbackPage', 'restaurantText')
    .split('%icon%');

  return (
    <Page name="Palaute" title={[translation.get('infoPage', 'title'), translation.get('feedbackPage', 'title')]}>
      <div className={styles.component}>
        <ScrollWrapper>
          <Container>
            <div className={styles.back}>
              <BackButton></BackButton>
            </div>
            <div className={styles.header}>
              <h1 className={styles.title}>{translation.get('feedbackPage', 'title')}</h1>
            </div>
            <div className={styles.content}>
              <StandardContent>
                <h2 className="h3">{translation.get('feedbackPage', 'restaurantSubtitle')}</h2>
                <p>
                  {restaurantTextBeforeIcon}
                  <span className={styles.inlineIcon}>
                    <Kebab />
                  </span>{' '}
                  {restaurantTextAfterIcon}
                </p>
                <h2 className="h3">{translation.get('feedbackPage', 'appSubtitle')}</h2>
                <p>{translation.get('feedbackPage', 'appText')}</p>
                <p>
                  <Button
                    text={translation.get('feedbackPage', 'appButtonText')}
                    url="mailto:feedback+sovellus@unicafe.fi?Subject=Feedback menu.unicafe.fi"
                    target="_blank"
                    theme="green"></Button>
                </p>
              </StandardContent>
            </div>
          </Container>
        </ScrollWrapper>
      </div>
    </Page>
  );
};

export default FeedbackPage;
